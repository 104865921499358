import React from "react";

const GcoRecrutement = (props) => {
  const { gcoRecrutement, post } = props;
  console.log("gcoRecrutement", gcoRecrutement);
   // Gco Recrutement
  /** JSON-LD - JobPosting
    * Article de recrutement (plugin Gco Recrutement) 
    * https://developers.google.com/search/docs/advanced/structured-data/job-posting?hl=fr
    * https://developers.google.com/search/docs/advanced/structured-data/job-posting?hl=fr#structured-data-type-definitions
    * https://schema.org/JobPosting
    * Tester le schema: https://validator.schema.org/
    * Tester les attentes de Google: https://search.google.com/test/rich-results
    * 🆘 Il faut coller le code avec les balises scripts du json-DL
  */
  if (gcoRecrutement) {
    // Nettoyage des balises interdites dans la description
    const mapObj = {
      "<\/?blockquote>": "",
      "<img.*?\/?>": "",
      "<\/?a.*?>": "",
      "<\/?strong>": "",
      "<\/?em>": "",
      "<\/?del.*?>": ""
    };
    const regex = new RegExp(Object.keys(mapObj).join("|"),"gis");
    gcoRecrutement.descriptionDeLoffre = gcoRecrutement.descriptionDeLoffre.replace(regex, "");
  }         
  const jsonLDGcoRecrutement = post.gcoRecrutement 
    ?
      {
        "@context" : "https://schema.org/",
        "@type" : "JobPosting",
        "title" : post.title,
        "description" : gcoRecrutement.descriptionDeLoffre,
        "identifier": {
          "@type": "PropertyValue",
          "name": gcoRecrutement.nomDeLentreprise,
          "value": post.id
        },
        "datePosted" : post.date.slice(0,10),
        "hiringOrganization": {
          "@type": "Organization",
          "name": gcoRecrutement.nomDeLentreprise,
          "sameAs": gcoRecrutement.siteWebDeLentreprise || props?.data?.site?.siteMetadata?.siteUrl || "",
        },
        "jobLocation": {
          "@type": "Place",
          "address": {
            "@type": "PostalAddress",
            "addressLocality": gcoRecrutement.adressePostale.ville,
            "addressRegion": gcoRecrutement.adressePostale.region,
            "postalCode": gcoRecrutement.adressePostale.codePostale,
            "streetAddress": gcoRecrutement.adressePostale.nomDeLaRue,
            "addressCountry": gcoRecrutement.adressePostale.codePays
          }
        }
      } 
    : null;

  return (<script
    type="application/ld+json"
    dangerouslySetInnerHTML={{
      __html: JSON.stringify(jsonLDGcoRecrutement)
    }}
  />)
};

export default GcoRecrutement;
