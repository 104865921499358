import React from "react";
import Parser from "../components/parser.js";
import Layout from "../components/layout/layout";
import { graphql } from "gatsby";
import GcoRecrutement from "../components/blocks/gco-recrutement.js";

const Post = (props) => {
  const post = props.data ? props.data.wpPost : props;
  const template = props.data ? props.data.wpTemplate.content : props.template;
  const postContent = () => {
    return (<Parser content={ post.content } />);
  };
  const gcoRecrutement =(typeof post.gcoRecrutement !== "undefined") && (post?.gcoRecrutement?.descriptionDeLoffre !== null) ? post.gcoRecrutement : false;

  return (
    <Layout infos={{
      id: post.id,
      title: post.title,
      postType: "post",
      category: post.categories.nodes[0],
      published: post.date,
      updated: post.modified,
      path: props.path,
    }} seo={ post.seo }>
      <>
        { gcoRecrutement
          ? <GcoRecrutement 
              gcoRecrutement={gcoRecrutement} 
              post={post}
            />
          : <></>
        }
        <Parser
          content={ template }
          child={ postContent }
          title={ post.title }
          coverImage={ post.featuredImage ? post.featuredImage.node : undefined }
          terms={{
            nodes: post.categories.nodes,
            asList: false,
            asLink: true
          }}
          date={ post.date }
        />
      </>
    </Layout>
  );
};

export const query = graphql`
  query($id: String!) {
    site {
      siteMetadata {
        siteUrl
      }
    }
    wpTemplate(template_reference: {referenceTemplate: {eq: "single"}}) {
      content
    }
    wpPost(id: {eq: $id}) {
      id
      title
      content
      date
      modified
      categories {
        nodes {
          name,
          uri
        }
      }
      featuredImage {
        node {
          localFile {
            childImageSharp {
              gatsbyImageData(width: 1500)
            }
          }
        }
      }
      gcoRecrutement {
        descriptionDeLoffre 
        fieldGroupName
        larticleEstUneOffreDemploi
        titreDeLoffre
        nomDeLentreprise
        siteWebDeLentreprise
        adressePostale {
          codePays
          codePostale
          fieldGroupName
          nomDeLaRue
          region
          ville
        }
      }
      seo {
        canonicalUrl
        description
        openGraphDescription
        openGraphTitle
        openGraphType
        title
        twitterDescription
        twitterTitle
        noIndex
        excludeLocalSearch
        excludeFromArchive
        noArchive
        noFollow
        redirectUrl
        socialImage {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  }
`;
export default Post;