import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout/layout";
import Parser from "../components/parser.js";

const Page = (props) => {
  const page = props.data ? props.data.wpPage : props;
  return (
    <Layout 
      infos={{
        title: page.title, 
        postType: "page", 
        path: props.pageContext.path, 
        parent: {
          url: props.pageContext.parentUrl,
          title: props.pageContext.parentTitle
        },
        hasGrandParent: props.pageContext.hasGrandParent
      }} 
      seo={ page.seo || {} }>
      <Parser content={ page.content } subQueries={ page.subQueries?.nodes || [] } />
    </Layout>
  );
};

export const query = graphql`
  query($id: String!) {
    wpPage(id: {eq: $id}) {
      title
      content
      seo {
        canonicalUrl
        description
        openGraphDescription
        openGraphTitle
        openGraphType
        title
        twitterDescription
        twitterTitle
        noIndex
        excludeLocalSearch
        excludeFromArchive
        noArchive
        noFollow
        redirectUrl
        socialImage {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
      subQueries {
        nodes {
          id
          relatedPostsIds
          posts {
            nodes {
              id
              excerpt
              uri
              title
              slug
              date
              categories {
                nodes {
                  slug
                  uri
                  id
                  name
                }
              }
              featuredImage {
                node {
                  altText
                  caption
                  localFile {
                    childImageSharp {
                      thumb: gatsbyImageData(width: 200)
                      small: gatsbyImageData(width: 400)
                      medium: gatsbyImageData(width: 800)
                      large: gatsbyImageData(width: 1000)
                      xl: gatsbyImageData(width: 1200)
                      full: gatsbyImageData
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
export default Page;
